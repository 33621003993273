/* You can add global styles to this file, and also import other style files */
//@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Roboto+Mono:wght@100;300;400;700&family=Roboto:wght@100;400;700&display=swap");
@import url("./fonts.scss");

$enable-responsive-font-sizes: false;

@import "bootstrap/scss/bootstrap";

html,
body {
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
}

.btn-icon {
  border: none;
  background: none;
  color: map-get($theme-colors, primary);
  cursor: pointer;
}

// fills the searchbox when adding a bootstrap button to the right.
.ais-SearchBox,
.ais-SearchBox-form,
.ais-SearchBox-input {
  height: 100%;
}

.form-sm,
formly-form {
  font-size: small;

  .mb-3 {
    margin-bottom: 0.75rem !important;
  }

  .form-label {
    margin-bottom: 0rem !important;
  }

  .input-group-text {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.2rem;
  }

  .form-control {
    min-height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
  }

  .form-select {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.25rem;
  }
}

.em-field-label {
  .form-label {
    font-weight: bold;
    font-size: larger;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.fk-busy {
  position: relative;
}

.fk-busy:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.fk-busy {
  // hide the text
  color: rgba(0, 0, 0, 0) !important;
}

// @include media-breakpoint-down(sm) {
//   .nav-tabs {
//     .nav-link {
//       font-size: 0;
//     }
//     .nav-link::first-letter {
//       font-size: 1rem;
//     }
//   }
// }

// @include media-breakpoint-down(sm) {
//   .nav-tabs {
//     .nav-item {
//       display: block;
//     }
//     .nav-link {
//       display: block;
//       // white-space: nowrap;
//       // max-width: 75px;
//       // overflow: hidden;
//       // text-overflow: ellipsis;
//       // margin-right: 0.5rem !important;
//     }
//   }
// }

.list-group-flush .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

.archived {
  text-decoration: line-through;
  opacity: 0.5;
}

fa-icon.btn {
  padding: 0 !important;
  font-size: inherit;
}

.archived {
  opacity: 0.7;
  text-decoration: line-through;
}

.form-switch-reverse .form-check-input {
  float: none !important;
  margin-left: 0.5rem;
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  padding: 0.5rem 0.25rem;
  margin: 0 1rem 0 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none;
  border-bottom: 3px solid $nav-tabs-border-color;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none;
  border-bottom: 3px solid map-get($theme-colors, primary);
  background-color: inherit;
}

h2 > .badge {
  position: relative;
  top: -0.4em;
  font-size: 0.5em;
}

.bg-osfa {
  background-color: #ee1c24 !important;
}

.bg-osfawa {
  background-color: #e95f63 !important;
}

.bg-orfa {
  background-color: rgb(0, 84, 39) !important;
}

.bg-ofca {
  background-color: rgb(107, 36, 41) !important;
}

.bg-bhbf {
  background-color: rgb(0, 131, 197) !important;
}

.bg-wc {
  background-color: rgb(65, 83, 93) !important;
}

#content > nav {
  box-shadow: none !important;
}

.row-data {
  margin-bottom: 1rem;
  .form-label {
    font-style: italic;
  }

  .form-text {
    font-weight: bold;
    color: black;
  }
}
.row-data:last-child {
  margin-bottom: 0;
}

.ctc {
  cursor: pointer;
}

.tooltip-no-arrow {
  .tooltip-arrow {
    display: none;
  }
}

.ais-Menu-count {
  display: none;
}

.ais-ToggleRefinement-count {
  display: none;
}
